import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/store/user-store';

const routes = [
  {
    path: '/',
    name: 'testing',
    component: () => import(/* webpackChunkName: "testing" */ '../views/TestingView.vue'),
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import (/* webpackChunkName: "analytics" */ '../views/AnalyticsView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/LogoutView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/testing/:id',
    name: 'runtest',
    component: () => import (/* webpackChunkName: "runtest" */ '../views/RunTestView.vue'),
    props: route => ({ dutIdx: parseInt(route.params.id) })
  },
  {
    path: '/debug',
    name: 'debug',
    component: () => import(/* webpackChunkName: "debug" */ '../views/DebugView.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView')
  },
  {
    path: '/runs',
    name: 'runs',
    component: () => import (/* webpackChunkName: "runs" */ '../views/RunsView.vue')
  },
  {
    path: '/run-viewer/:id',
    name: 'run-viewer',
    component: () => import (/* webpackChunkName: "run-viewer" */ '../views/RunViewer.vue'),
    props: route => ({ listingId: route.params.id })
  },
  {
    path: '/test-plans/edit/:id',
    name: 'test-plan-edit',
    component: () => import (/* webpackChunkName: "test-plans-edit" */ '../views/TestPlanEditView.vue'),
    props: route => ({ listingId: route.params.id })
  },
  {
    path: '/test-plans/manage/:id',
    name: 'test-plan-manage',
    component: () => import (/* webpackChunkName: "test-plans-edit" */ '../views/TestPlanManageView.vue'),
    props: route => ({ listingId: route.params.id })
  },
  {
    path: '/test-plans/add',
    name: 'test-plan-add',
    component: () => import (/* webpackChunkName: "test-plans-add" */ '../views/TestPlanAddView.vue')
  },
  // No Test Plan Selected Operator View
  {
    path: '/test-plans/no-plan',
    name: 'no-plan',
    component: () => import (/* webpackChunkName: "no-plan" */ '../views/NoTestPlanSelected.vue')
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('../views/ErrorNotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const { checkLoggedIn } = useUserStore();
  await checkLoggedIn ();

  const { getIsLoggedIn } = useUserStore();

  const publicRoutes = ['login', 'about'];

  if (!getIsLoggedIn && !publicRoutes.includes(to.name)) {
    // If the user isn't logged in and the route isn't public, redirect to login
    next({ name: 'login' });
  } else if (getIsLoggedIn && to.name === 'login') {
    // If the user is already logged in and tries to access the login page, redirect to home
    next({ name: 'home' });
  } else {
    // Otherwise, proceed to the route
    next();
  }
})

export default router
